import analytics from '@capturi/analytics'
import { useHover } from '@capturi/react-utils'
import {
  Box,
  Icon,
  IconButton,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Portal,
} from '@chakra-ui/react'
import { Trans, t } from '@lingui/macro'
import React, { CSSProperties, useRef } from 'react'
import {
  MdContentCopy,
  MdDelete,
  MdEdit,
  MdLaunch,
  MdMoreHoriz,
} from 'react-icons/md'

import EditableWidgetContext from '../../contexts/EditableWidgetContext'
import useWidgetFilterLink from '../../hooks/useWidgetFilterLink'
import { WidgetModel } from '../../types'
import { getColorValue } from '../../widgets/colors'
import { useWidgetDefinition } from '../../widgets/registry'
import { BackgroundColorSubMenuItem, ColorValue } from './WidgetBackgroundMenu'
import WidgetCard from './WidgetCard'
import WidgetErrorBoundary from './WidgetErrorBoundary'
import WidgetLoader from './WidgetLoader'

type EditableWidgetProps = {
  widget: WidgetModel
  currentUserCanEdit: boolean
  children?: React.ReactNode
  onEdit: (widget: WidgetModel) => void
  onClone: (widget: WidgetModel) => void
  onDelete: (widget: WidgetModel) => void
  onBackgroundChange: (widget: WidgetModel, color?: ColorValue) => void
  dragging: boolean
}

interface ReactGridItemProps {
  style?: CSSProperties
  className?: string
  onMouseDown?: React.MouseEventHandler<HTMLDivElement>
  onMouseUp?: React.MouseEventHandler<HTMLDivElement>
  onTouchEnd?: React.TouchEventHandler<HTMLDivElement>
}

const disabledLinksVisualTypes = ['List', 'TeamList', 'UserList']

const EditableWidget = React.forwardRef<
  HTMLDivElement,
  EditableWidgetProps & ReactGridItemProps
>(
  (
    {
      // EditableWidgetProps
      widget,
      currentUserCanEdit,
      dragging,
      // resize handles from react-grid-layout are injected into children
      children: resizeHandles,
      onEdit,
      onClone,
      onDelete,
      onBackgroundChange,

      // ReactGridItemProps
      style,
      className,
      onMouseDown,
      onMouseUp,
      onTouchEnd,
      ...restProps
    },
    ref,
  ) => {
    const { Component, Configurator } = useWidgetDefinition(widget.type)

    const widgetCardRef = useRef<HTMLDivElement>(null)
    const isHovering = useHover(widgetCardRef)
    const widgetLink = useWidgetFilterLink({ widget })

    const isEditable = Configurator != null

    const editableWidgetCtx = {
      onEditWidget: () => onEdit(widget),
    }

    const widgetLinkEnabled = !disabledLinksVisualTypes.includes(widget.visual)

    return (
      <WidgetCard
        // ReactGridItemProps
        style={style}
        className={className}
        ref={ref}
        onMouseDown={onMouseDown}
        onMouseUp={onMouseUp}
        onTouchEnd={onTouchEnd}
        dragging={dragging}
        // biome-ignore lint/a11y/useSemanticElements: need group here
        role="group"
        cursor={currentUserCanEdit ? 'move' : 'default'}
        colorName={widget.backgroundColor}
        {...restProps}
      >
        <Box width="100%" height="100%" ref={widgetCardRef}>
          <EditableWidgetContext.Provider value={editableWidgetCtx}>
            <WidgetErrorBoundary widget={widget}>
              <React.Suspense fallback={<WidgetLoader />}>
                <Component {...widget} />
              </React.Suspense>
            </WidgetErrorBoundary>
          </EditableWidgetContext.Provider>
          {currentUserCanEdit && (
            <Menu isLazy>
              {({ onClose }) => (
                <>
                  <Box
                    w={widgetLinkEnabled ? '70px' : '40px'}
                    h="8"
                    position="absolute"
                    top={1}
                    transition="opacity 50ms ease-in"
                    opacity={isHovering ? '1' : '0'}
                    _groupHover={{
                      bg: `radial-gradient(ellipse at 50%, ${getColorValue(
                        widget.backgroundColor,
                      )} 0%, ${getColorValue(
                        widget.backgroundColor,
                      )} 50%, rgba(9,9,121,0) 100%);`,
                    }}
                    right={1}
                  >
                    {widgetLink && widgetLinkEnabled && (
                      <Link
                        href={widgetLink}
                        position="absolute"
                        top={1}
                        right={10}
                        onClick={() => {
                          analytics.event('dashboard_widget_link', {
                            widgetType: widget.type,
                            visual: widget.visual,
                          })
                        }}
                        isExternal
                      >
                        <IconButton
                          size="xs"
                          bg="transparent"
                          aria-label="Segment link"
                          icon={<MdLaunch />}
                        />
                      </Link>
                    )}

                    <MenuButton
                      size="xs"
                      as={IconButton}
                      position="absolute"
                      top={1}
                      right={1}
                      aria-label={t`Edit widget`}
                      icon={<Icon as={MdMoreHoriz} boxSize={4} />}
                      onMouseDown={(e) => {
                        // Prevent triggering dragStart in react-grid-layout
                        e.stopPropagation()
                      }}
                    />
                  </Box>
                  <Portal>
                    <MenuList
                      onMouseDown={(e) => {
                        // Prevent triggering dragStart in react-grid-layout
                        e.stopPropagation()
                      }}
                    >
                      <MenuItem
                        icon={<MdEdit />}
                        onClick={() => onEdit(widget)}
                        isDisabled={!isEditable}
                      >
                        <Trans>Edit</Trans>
                      </MenuItem>

                      <BackgroundColorSubMenuItem
                        onSelectColor={(color) =>
                          onBackgroundChange(widget, color)
                        }
                        onClose={onClose}
                      />
                      <MenuItem
                        icon={<MdContentCopy />}
                        onClick={() => onClone(widget)}
                        isDisabled={!isEditable}
                      >
                        <Trans>Clone</Trans>
                      </MenuItem>
                      <MenuItem
                        icon={<MdDelete />}
                        onClick={() => onDelete(widget)}
                      >
                        <Trans>Delete</Trans>
                      </MenuItem>
                    </MenuList>
                  </Portal>
                </>
              )}
            </Menu>
          )}
          {resizeHandles}
        </Box>
      </WidgetCard>
    )
  },
)

export default EditableWidget
